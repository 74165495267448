import React from 'react'
import { useTranslation, I18nContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'

const ArrowheadTools = () => {
  const { i18n } = React.useContext(I18nContext)
  const { t } = useTranslation()

  return (
      <Layout>
          <SEO title="Arrowhead-Tools" language={i18n.language} />
          <div className="arrowhead-tools-page">
              <div className="arrowhead-tools-page-header">
                  <div className="arrowhead-tools-page-header-logo">
                      <div className="arrowhead-tools-page-header-logo-img" />
                  </div>
                  <div className="arrowhead-tools-page-header-vision">
                      <p>{t('arrowhead-tools-vision').toUpperCase()}</p>
                  </div>
              </div>
              <div className="arrowhead-tools-page-content">
                  <div className="arrowhead-tools-page-content-card">
                      <div className="arrowhead-tools-page-content-card-header">
                          <div className="arrowhead-tools-page-content-card-header-logo arrowhead-tools-page-content-card-header-logo-cert-gen" />
                          <div className="arrowhead-tools-page-content-card-header-title"><p>Arrowhead Certificate Generator</p></div>
                      </div>
                      <div className="arrowhead-tools-page-content-card-teaser">
                          <div className="arrowhead-tools-page-content-card-teaser-pic arrowhead-tools-page-content-card-teaser-pic-cert-gen" />
                          <div className="arrowhead-tools-page-content-card-teaser-desc"><p>{t('arrowhead-tools-cert-gen-desc')}</p></div>
                      </div>
                      <div className="arrowhead-tools-page-content-card-actions">
                          <div><a href="/downloads/ah-cert-gen">{t('download')}</a></div>
                          <div className="arrowhead-tools-page-content-card-actions-divider">|</div>
                          <div><a href="https://raw.githubusercontent.com/Aitia-IIOT/arrowhead-tools-aitia/master/arrowhead-certificate-generator/downloads/LICENSE.txt">{t('read-license')}</a></div>
                          <div className="arrowhead-tools-page-content-card-actions-divider">|</div>
                          <div><a href="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/issues">{t('report-a-bug')}</a></div>
                          <div className="arrowhead-tools-page-content-card-actions-divider">|</div>
                          <div><a href="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/issues/35">FAQ</a></div>
                      </div>
                  </div>
                  <div className="arrowhead-tools-page-content-card">
                      <div className="arrowhead-tools-page-content-card-header">
                          <div className="arrowhead-tools-page-content-card-header-logo arrowhead-tools-page-content-card-header-logo-installer" />
                          <div className="arrowhead-tools-page-content-card-header-title"><p>Arrowhead Framework Installer</p></div>
                      </div>
                      <div className="arrowhead-tools-page-content-card-teaser">
                          <div className="arrowhead-tools-page-content-card-teaser-pic arrowhead-tools-page-content-card-teaser-pic-installer" />
                          <div className="arrowhead-tools-page-content-card-teaser-desc"><p>{t('arrowhead-tools-installer-desc')}</p></div>
                      </div>
                      <div className="arrowhead-tools-page-content-card-actions">
                          <div><a href="/downloads/ah-installer">{t('download')}</a></div>
                          <div className="arrowhead-tools-page-content-card-actions-divider">|</div>
                          <div><a href="https://raw.githubusercontent.com/Aitia-IIOT/arrowhead-tools-aitia/master/arrowhead-framework-installer/downloads/LICENSE.txt">{t('read-license')}</a></div>
                          <div className="arrowhead-tools-page-content-card-actions-divider">|</div>
                          <div><a href="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/issues">{t('report-a-bug')}</a></div>
                          <div className="arrowhead-tools-page-content-card-actions-divider">|</div>
                          <div><a href="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/issues/35">FAQ</a></div>
                      </div>
                  </div>
                  <div className="arrowhead-tools-page-content-card">
                      <div className="arrowhead-tools-page-content-card-header">
                          <div className="arrowhead-tools-page-content-card-header-logo arrowhead-tools-page-content-card-header-logo-mgmt-tool" />
                          <div className="arrowhead-tools-page-content-card-header-title"><p>Arrowhead Management Tool</p></div>
                      </div>
                      <div className="arrowhead-tools-page-content-card-teaser">
                          <div className="arrowhead-tools-page-content-card-teaser-pic arrowhead-tools-page-content-card-teaser-pic-mgmt-tool" />
                          <div className="arrowhead-tools-page-content-card-teaser-desc"><p>{t('arrowhead-tools-mgmt-tool-desc')}</p></div>
                      </div>
                      <div className="arrowhead-tools-page-content-card-actions">
                          <div><a href="/downloads/ah-mgmt-tool">{t('download')}</a></div>
                          <div className="arrowhead-tools-page-content-card-actions-divider">|</div>
                          <div><a href="https://raw.githubusercontent.com/Aitia-IIOT/arrowhead-tools-aitia/master/arrowhead-management-tool/downloads/LICENSE.txt">{t('read-license')}</a></div>
                          <div className="arrowhead-tools-page-content-card-actions-divider">|</div>
                          <div><a href="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/issues">{t('report-a-bug')}</a></div>
                          <div className="arrowhead-tools-page-content-card-actions-divider">|</div>
                          <div><a href="https://github.com/Aitia-IIOT/arrowhead-tools-aitia/issues/35">FAQ</a></div>
                      </div>
                  </div>
              </div>
              <div className="arrowhead-tools-page-links">
                  <div className="arrowhead-tools-page-links-btn-container">
                      <a className="btn btn--blue" href="https://tools.arrowhead.eu/home/" target="_blank" rel="noreferrer">
                          <i className="fas fa-link">
                              {' tools.arrowhead.eu'}
                          </i>
                      </a>
                  </div>
              </div>
          </div>
      </Layout>
  )
}

export default ArrowheadTools

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "header", "products", "arrowhead", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
